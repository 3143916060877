/**
 * utmSourceCnzz.js
 *
 * Release 3.0.4
 * @author <a href="mailto:wanghailiang@diditaxi.com.cn">Haley Wang</a>
 *
 * modify by hanxingwang : 添加自定义source值，属性名：data-default 或 data-defined
 * html标签添加全页面来源：<html data-default="aliyun">
 * 个别dom添加自己定义来源：<a href="http://es.xiaojukeji.com" data-defined="es"></a>
 * href值会自动变更为:http://es.xiaojukeji.com?__utm_source=es
 *
 */

var isMobile = /iphone|nokia|sony|ericsson|mot|samsung|sgh|lg|philips|panasonic|alcatel|lenovo|cldc|midp|wap|android|iPod/i.test(navigator.userAgent.toLowerCase());
//https://gist.github.com/chrisjlee/8960575
var defaultSource, bodySource = document.documentElement.getAttribute("data-default");
defaultSource = bodySource ? bodySource : 'nosource';   //设置页面默认source来源
var _czc = _czc || [], source = (isMobile? defaultSource+'':defaultSource); // CNZZ

(function (global, factory) {
  if (typeof exports === "object" && exports) {
    factory(exports); // CommonJS
  } else if (typeof define === "function" && define.amd) {
    define(['exports'], factory); // AMD
  } else {
    factory(global.UtmSource = {}); // <script>
  }
}(window, function (UtmSource) {
    /**
     * 解析URL
     */
    UtmSource.parseUrl = function(url){
        var a = document.createElement('a');
        a.href = url;

        return {
            source: url,
            protocol: a.protocol.replace(':',''),
            host: a.hostname,
            port: a.port,
            query: a.search,
            params: (function(){
                var ret = {}, seg = a.search.replace(/^\?/,'').split('&'), s;
                for (var i=0, len=seg.length; i<len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [,''])[1],
            hash: a.hash.replace('#',''),
            path: a.pathname.replace(/^([^\/])/,'/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [,''])[1],
            segments: a.pathname.replace(/^\//,'').split('/')
        };
    };
    UtmSource.getParm = function(name){
        if(!name || name == "")
        return "";
        var r = new RegExp("(\\?|#|&)*"+name+"=([^&\\#\\?]*)");
        var m = location.href.match(r);
        return (!m?"":m[2]);
    };
    UtmSource.isUrl = function(){

    };
    UtmSource.dispose = function(tag){
        var attr = tag.nodeName=='A'?'href':'action';

        var url = tag.getAttribute(attr);
        if(/^(tel|javascript)\:/.test(url)){
            return
        }
        if(url == null
         || url == ""
         || (!/^(?:((?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url)
         && !/(\/?[\w-]*)(\/[\w-]+)*\/?|(((http|ftp|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?)/gi.test(url))
         || (url.indexOf('?') > -1 && url.indexOf(this.name) > -1)
         || /^#.*/.test(url)/*判断url是否只是一个hash*/){//如果标签里没有声明该属性，则getAttribute会返回null
            return;
        }
        var defined = tag.getAttribute("data-defined");
        // var utm = source;
        var utm = defined ? defined : source;   // 判断有自定义属性data-defined并且utm=nosource时，来源为自定义的属性值
        // console.log(this.name,utm);
        //by HP14:53 如果原来链接中已经有__utm_source,则跳过不再处理
        //if(url.indexOf('?') > -1&&url.indexOf(this.name)){return}
        if(utm && this.getQueryString('__utm_source',url) === null){
            var query = UtmSource.getUtmSourceQueryString(utm);
            url = this.addQueryString(url,'__utm_source', utm);
            tag.setAttribute(attr, url);
        }
    };
    UtmSource.getQueryString = function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    UtmSource.getUtmSourceQueryString = function(utm) {
        var query = this.name + '=' + (utm || source);
        return query
    };
    UtmSource.addUtmSourceQueryString = function(url) {
        var queryString = UtmSource.getUtmSourceQueryString(source);
        url = UtmSource.addQueryString(url, this.name, source)

        return url;
    };
    UtmSource.addQueryString = function(url, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
          if (url.match(re)) {
            return url.replace(re, '$1' + key + "=" + value + '$2');
          } else {
            var hash =  '';
            if( url.indexOf('#') !== -1 ){
                hash = url.replace(/.*#/, '#');
                url = url.replace(/#.*/, '');
            }
            var separator = url.indexOf('?') !== -1 ? "&" : "?";    
            return url + separator + key + "=" + value + hash;
          }
        /*if (queryString) {
            var isQuestionMarkPresent = url && url.indexOf('?') !== -1,
            separator = isQuestionMarkPresent ? '&' : '?';
            url += separator + queryString;
        }*/

        return url;
    };
    // 事件上报数据
    UtmSource.click = function(tag){
        var self = this;
        tag.onclick = function(){
            var title = this.getAttribute('title');
            self.push(['_trackEvent', source, title]);
            return true;
        };
    };
    // 初始化
    UtmSource.init = function(config){
        this.config = config || {};
        this.name = this.config.name || '__utm_source';
        var self = this;

        function distribute(obj){
            self.dispose(obj);
            var boss = obj.getAttribute('boss');
            if(boss){
                /*2016/05/18 由于BI要求将站内所有链接都加上__utm_source，所以不管有没有boss属性，只要是a标签或form，其href和action都加上__utm_source*/
                /*if ( boss == 'source') {
                    self.dispose(obj);
                }else if( boss == 'click'){
                    self.click(obj);
                }*/
                if( boss == 'click'){
                    self.click(obj);
                }
            }
        }

        if(document.querySelectorAll){
            var tags = document.querySelectorAll('a, form');
            for(var i=0, len=tags.length; i<len; i++){
                distribute(tags[i]);
            }
        }else{
            var tagNames = ['a', 'form'];
            for(var i=0, len=tagNames.length; i<len; i++){
                var tags = document.getElementsByTagName(tagNames[i]);
                for(var j=0, l=tags.length; j<l; j++){
                    distribute(tags[j]);
                }
            }
        }
    };

    UtmSource.push = function(params){
        if( _czc ){
            _czc.push(params);
        }
    };

    UtmSource.setCnzz = function(){
        // 适配CNZZ
        _czc.push(["_setAccount", "1255043551"]);
        var utmParam = UtmSource.getParm('__utm_source') || UtmSource.getParm('from');

        if(!utmParam){
            if( document.referrer ){
                var obj = UtmSource.parseUrl( document.referrer );
                var host = obj.host.replace(/\./ig, '_');
                if(host != 'es_xiaojukeji_com' && host != 'static_xiaojukeji_com' && host != 'static-es_xiaojukeji_com'){
                    source = isMobile ? (host+'') : host;
                }
            }
        }else{
            source = utmParam;
            if(!/.*?_m$/.test(utmParam)&&isMobile){
                source = source + '';
            }
        }
    };

    UtmSource.setCnzz();
    return UtmSource
}));
